
import { defineComponent } from 'vue';
import {
  IonList,
  IonChip,
  IonAvatar,
  IonLabel,
  IonItem,
  IonIcon,
} from '@ionic/vue';
import { useI18n } from 'vue-i18n';
import { cloudDownloadOutline } from 'ionicons/icons'
import moment from 'moment';
import DownloadService from "@/services/download.service";
import {mapGetters} from "vuex";
import { documentAttachOutline } from 'ionicons/icons';

export default defineComponent({
  name: 'Message',
  props: {
    name: {
      type: String,
      required: true,
    },
    message: {
      type: Object,
      required: true,
    },
    profilePicture: {
      type: String,
      required: true,
    },
    isFirst: {
      default: false,
      type: Boolean,
    }
  },
  components: {
    IonList,
    IonItem,
    IonChip,
    IonAvatar,
    IonLabel,
    IonIcon,
  },
  setup() {
    const { t } = useI18n();

    return {
      t,
      moment,
      cloudDownloadOutline,
      documentAttachOutline,
    };
  },
  computed: {
    ...mapGetters({
      locale: 'app/locale',
    }),

    getVideoPosterUrl(): string {
      if (this.message.vimeo_poster) {
        return this.message.vimeo_poster;
      }

      if (this.message.attachments.length > 0 && this.message.attachments[0].type === 'video') {
        return this.message.attachments[0].vimeo_poster;
      }

      return 'assets/default-video-image.png';
    },

    videoUrls(): any {
      const urls = [];

      if (this.message.attachments.length > 0) {
        for (const attachment of this.message.attachments) {
          if (attachment.type === 'video' && attachment.vimeo_status === 'available') {
            urls.push(attachment)
          }
        }
      }

      return urls;
    },

    vimeoUrl(identifier: any) {
      console.log(identifier);
      return "https://player.vimeo.com/video/";
    }
  },
  methods: {
    download(url: string, name: string) {
      DownloadService.downloadFile(url, name);
    },
  },
});
