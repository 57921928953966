
import {defineComponent, ref} from "vue";
import moment from "moment";
import { IonModal } from "@ionic/vue";
import ApiService from "@/services/api.service";
import Button from "@/components/Button.vue";
import Upload from "@/components/Upload.vue";
import Modal from "@/components/Modal.vue";
import InputGroup from "@/components/InputGroup.vue";
import {useI18n} from "vue-i18n";
import Message from "@/components/Message.vue";
import SelectRecipients from "@/components/SelectRecipients.vue";

export default defineComponent({
  name: 'Comments',

  components: {
    Message,
    Button,
    IonModal,
    Modal,
    Upload,
    InputGroup,
    SelectRecipients,
  },

  props: {
    id: {
      type: Number,
      required: true
    },
  },

  data() {
    return {
      record: {} as any,
      loading: false,
      message: '',
      recipients: [],
      isSending: false,
    };
  },

  methods: {
    async getNote() {
      this.loading = true;
      await ApiService.get(`/notepad/${this.id}`).then(({data}) => {
        this.record = data;
      });
      this.loading = false;
    },

    setRecipients(recipients: []) {
      this.recipients = recipients;
    },

    changeCount(count: number) {
      this.$emit('change-count', count);
    },

    sendMessage() {
      if (this.isSending) {
        return;
      }

      this.isSending = true;

      ApiService.put(`/notepad/${this.id}`, {
        message: this.message,
        recipients: this.recipients,
        // eslint-disable-next-line @typescript-eslint/camelcase
        upload_identifiers: this.uploadRef.getFileIds(),
      }).then(() => {
        this.isSending = false;
        this.getNote();
        this.message = '';
        this.recipients = [];
        this.setOpen(false);

        this.$nextTick(() => {
          this.$forceUpdate();
        })
      })
    },
  },

  created() {
    this.getNote();
  },

  setup() {
    const { t } = useI18n();
    const isOpenRef = ref(false);
    const setOpen = (state: boolean) => isOpenRef.value = state;
    const uploadRef = ref(Upload);

    return {moment, setOpen, isOpenRef, uploadRef, t};
  },
});
