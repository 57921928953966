
import { IonModal, IonButton } from "@ionic/vue";
import {defineComponent, ref} from "vue";
import ApiService from "@/services/api.service";
import {useI18n} from "vue-i18n";
import {mapActions, mapGetters} from "vuex";
import SearchModal from "@/components/SearchModal.vue";

export default defineComponent({
  name: 'SelectRecipients',

  components: {
    SearchModal,
    IonButton,
    IonModal,
  },

  computed: {
    ...mapGetters("permission", ["getPermission"]),

    searchableRecipients(): any {
      return this.recipients.map((index: any) => {
        return {
          id: index.id,
          label: index.full_name
        }
      })
    },
  },

  data() {
    return {
      selectedRecipients: [] as number[],
      recipients: [] as object[],
      showSelect: true,
      isOpen: false,
    };
  },

  props: {
    initialValue: {
      type: String,
      default: null,
    },
    variant: {
      type: String,
      default: null,
    },

    useTeam: {
      type: Boolean,
      defaul: false,
    },
  },

  watch: {
    'selectedRecipients': {
      deep: true,
      handler() {
        this.$emit('recipients', this.selectedRecipients);
      }
    }
  },

  methods: {
    ...mapActions("permission", ["fetchPermission"]),

    getRecipients() {
      const url = this.useTeam ? '/team' : '/message/possibleRecipients';

      ApiService.get(url).then(({data}) => {
        this.recipients = data;
      });
    },

    setSelectedRecipients(records: number[]) {
      this.selectedRecipients = records;
    },
  },

  async created() {
    await this.fetchPermission();

    if (parseInt(this.initialValue) > 0) {
      this.showSelect = false;
      this.selectedRecipients = [parseInt(this.initialValue), ...this.selectedRecipients];
      await this.$nextTick(() => {
        /* Fix for IONIC SELECT */
        setTimeout(() => {
          this.showSelect = true;
        }, 250)
      });
    }

    this.getRecipients();
  },

  setup() {
    const {t} = useI18n()
    const isOpenRef = ref(false);
    const setOpen = (state: boolean) => isOpenRef.value = state;

    return {
      t,
      isOpenRef,
      setOpen,
    }
  },
});
