
import {
  IonItem,
  IonLabel,
  IonModal,
  IonFab,
  IonFabButton,
  IonIcon,
  IonToggle,
  actionSheetController,
  alertController,
  useBackButton,
} from '@ionic/vue'
import {useRouter} from 'vue-router';
import {defineComponent, ref} from "vue";
import ApiService from "@/services/api.service";
import {chevronForwardOutline, sendOutline, add, funnelOutline} from 'ionicons/icons';
import InputGroup from "@/components/InputGroup.vue";
import Button from "@/components/Button.vue";
import Container from "@/views/layout/Container.vue";
import moment from "moment";
import {useI18n} from "vue-i18n";
import Modal from "@/components/Modal.vue";
import Upload from "@/components/Upload.vue";
import mixin from "@/mixin";
import {mapActions, mapGetters} from "vuex";
import Loading from "@/components/Loading.vue";
import Cards from "@/components/notes/Cards.vue";
import SelectRecipients from "@/components/SelectRecipients.vue";

export default defineComponent({
  name: 'Notes',
  components: {
    Loading,
    Cards,
    Upload,
    Container,
    IonFab,
    IonFabButton,
    IonIcon,
    InputGroup,
    Button,
    IonModal,
    Modal,
    SelectRecipients,
  },
  setup() {
    const router = useRouter();
    const {t} = useI18n()

    const isOpenRef = ref(false);
    const setOpen = (state: boolean) => isOpenRef.value = state;

    const uploadRef = ref(Upload);

    return {
      t,
      router,
      isOpenRef,
      setOpen,
      chevronForwardOutline,
      sendOutline,
      funnelOutline,
      moment,
      add,
      uploadRef,
    };
  },

  mixins: [
    mixin
  ],

  data() {
    return {
      records: [],
      message: '',
      isPrivate: false,
      loading: false,
      recipients: [],
      filters: [],
      activeFilter: '',
      isSending: false,
      backButton: [] as any[],
      files: 0,
    };
  },

  computed: {
    ...mapGetters({
      getPermission: 'permission/getPermission',
    }),

    patientSelected(): boolean {
      return this.$route.query.patient !== undefined && this.getPermission('user.type')?.toLowerCase() === 'professional';
    }
  },
  methods: {
    ...mapActions("permission", ["fetchPermission"]),

    ionViewWillEnter() {
      if (this.patientSelected) {
        this.setOpen(true);
      }

      this.getNotes();
      this.getFilters();
    },

    ionViewWillLeave() {
      this.backButton.forEach(b => b());
    },

    setCount(count: number) {
      this.files = count;
    },

    async getFilters() {
      const { data } = await ApiService.get('/notepad/functions');

      this.filters = data.data;
    },

    async getNotes() {
      this.loading = true;
      let filter = '';

      if (this.activeFilter) {
        filter = `?function=${this.activeFilter}`;
      }

      await ApiService.get(`/notepad${filter}`).then(({data}) => {
        this.records = data;
      })
      this.loading = false;
    },

    async showFilterSheet() {
      const buttons = this.filters.map((filter: string) => {
        return {
          text: filter,
          handler: () => {
            this.activeFilter = filter;
            this.getNotes();
          }
        };
      }) as any[];

      buttons.push({
        text: this.t('notes.everyone'),
        handler: () => {
          this.activeFilter = '';
          this.getNotes();
        },
      })

      buttons.push({
        text: this.t('cancel'),
        role: 'cancel',
      });

      const sheet = await actionSheetController.create({
        header: this.t('notes.showMessages'),
        buttons,
      });

      await sheet.present();
    },

    setRecipients(recipients: []) {
      this.recipients = recipients;
    },

    sendMessage() {
      if (this.isSending) {
        return;
      }

      this.isSending = true;
      const url = this.patientSelected ? `/admin/patient/${this.$route.query.patient}/notepad` : '/notepad';

      ApiService.post(url, {
        message: this.message,
        recipients: this.recipients,
        // eslint-disable-next-line @typescript-eslint/camelcase
        is_private: this.isPrivate,
        // eslint-disable-next-line @typescript-eslint/camelcase
        upload_identifiers: this.uploadRef.completedUploads.map((object: any) => {
          return object.fileId;
        }),
      }).then(() => {
        this.isSending = false;
        this.message = '';
        this.recipients = [];
        this.dismissModal();

        if (this.uploadRef) {
          this.uploadRef.completedUploads = [];
        }

        this.getNotes();
      });
    },

    dismissModal() {
      this.setOpen(false);

      if (this.$route.name !== 'notes' && this.patientSelected) {
        this.$router.replace(`/patients/${this.$route.query.patient}`);
      }
    }
  },
});
