<template>
  <div>
    <EmptyState class="ion-margin" v-if="records.length === 0" :message="t('notes.noNotes')" />
    <IonCard class="card-note" v-for="record in records" :key="record.id">
      <IonCardContent>
        <Loading v-show="loading"/>
        <IonItem lines="none" class="ion-no-padding ion-no-margin card-note__item ion-align-items-center">
          <IonAvatar slot="start" class="ion-no-padding ion-no-margin ion-margin-end">
            <img :src="record.creator.profile_picture">
          </IonAvatar>
          <IonLabel>
            {{ record.creator.full_name }}
            <p class="card-note__time">{{ moment(record.updated_at, 'DD-MM-YYYY H:mm').locale(locale).format('D MMMM (HH:mm)') }}</p>
          </IonLabel>
        </IonItem>
        <Comments :id="record.id" @change-count="$event => $emit('change-count', $event)" />
      </IonCardContent>
    </IonCard>
  </div>
</template>

<script type="ts">
import {
  IonCard,
  IonCardContent,
  IonAvatar,
  IonItem,
  IonLabel,
} from '@ionic/vue'
import {useRouter} from 'vue-router';
import {defineComponent} from "vue";
import moment from "moment";
import {useI18n} from "vue-i18n";
import {mapActions, mapGetters} from "vuex";
import Loading from "@/components/Loading.vue";
import Comments from "@/components/notes/Comments.vue";
import EmptyState from "@/components/EmptyState.vue";

export default defineComponent({
  name: 'Cards',
  components: {
    EmptyState,
    Comments,
    Loading,
    IonCard,
    IonCardContent,
    IonAvatar,
    IonItem,
    IonLabel,
  },
  props: {
    records: {
      type: Array,
      required: true,
    },
    loading: {
      type: Boolean,
      required: true,
    },
    title: String,
  },

  setup() {
    const router = useRouter();
    const {t} = useI18n();

    return {
      t,
      router,
      moment,
    };
  },

  computed: {
    ...mapGetters("permission", ["getPermission"]),
    ...mapGetters({
      locale: 'app/locale',
    }),
  },

  methods: {
    ...mapActions("permission", ["fetchPermission"]),
  },

  created() {
    this.fetchPermission();
  }
});
</script>

<style lang="scss" scoped>
.card-note {
  margin: 5px 5px 25px 5px;

  &__item {
    display: block;
    margin-bottom: 1rem;
  }

  &__title {
    display: flex;
  }

  &__time {
    font-size: 0.75rem;
  }
}
</style>
