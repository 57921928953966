import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_IonTitle = _resolveComponent("IonTitle")!
  const _component_IonIcon = _resolveComponent("IonIcon")!
  const _component_IonToolbar = _resolveComponent("IonToolbar")!
  const _component_IonSearchbar = _resolveComponent("IonSearchbar")!
  const _component_IonHeader = _resolveComponent("IonHeader")!
  const _component_IonCheckbox = _resolveComponent("IonCheckbox")!
  const _component_IonLabel = _resolveComponent("IonLabel")!
  const _component_IonItem = _resolveComponent("IonItem")!
  const _component_IonList = _resolveComponent("IonList")!
  const _component_IonContent = _resolveComponent("IonContent")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_IonHeader, null, {
      default: _withCtx(() => [
        _createVNode(_component_IonToolbar, null, {
          default: _withCtx(() => [
            _createVNode(_component_IonTitle, null, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.title), 1)
              ]),
              _: 1
            }),
            _createVNode(_component_IonIcon, {
              class: "closeIcon",
              onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('dismiss'))),
              slot: "end",
              icon: _ctx.checkmarkOutline
            }, null, 8, ["icon"])
          ]),
          _: 1
        }),
        _createVNode(_component_IonToolbar, null, {
          default: _withCtx(() => [
            _createVNode(_component_IonSearchbar, {
              modelValue: _ctx.query,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.query) = $event)),
              clearInput: ""
            }, null, 8, ["modelValue"])
          ]),
          _: 1
        })
      ]),
      _: 1
    }),
    _createVNode(_component_IonContent, null, {
      default: _withCtx(() => [
        (_ctx.filteredRecords)
          ? (_openBlock(), _createBlock(_component_IonList, { key: 0 }, {
              default: _withCtx(() => [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.filteredRecords, (record) => {
                  return (_openBlock(), _createBlock(_component_IonItem, {
                    key: record.id,
                    class: "ion-no-padding ion-no-margin"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_IonCheckbox, {
                        "onUpdate:modelValue": ($event: any) => ($event ? _ctx.selectedRecords.push(record.id) : _ctx.selectedRecords.splice(_ctx.selectedRecords.findIndex((element) => { return element === record.id }), 1)),
                        modelValue: _ctx.selectedRecords.includes(record.id),
                        name: record.id,
                        value: record.id,
                        class: "ion-margin"
                      }, null, 8, ["onUpdate:modelValue", "modelValue", "name", "value"]),
                      _createVNode(_component_IonLabel, null, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(record.label), 1)
                        ]),
                        _: 2
                      }, 1024)
                    ]),
                    _: 2
                  }, 1024))
                }), 128))
              ]),
              _: 1
            }))
          : _createCommentVNode("", true)
      ]),
      _: 1
    })
  ], 64))
}