import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SearchModal = _resolveComponent("SearchModal")!
  const _component_IonModal = _resolveComponent("IonModal")!
  const _component_IonButton = _resolveComponent("IonButton")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_IonModal, {
      "is-open": _ctx.isOpenRef,
      onDidDismiss: _cache[1] || (_cache[1] = ($event: any) => (_ctx.setOpen(false)))
    }, {
      default: _withCtx(() => [
        _createVNode(_component_SearchModal, {
          onDismiss: _cache[0] || (_cache[0] = ($event: any) => (_ctx.setOpen(false))),
          title: _ctx.variant === 'notes' || _ctx.variant === 'notify' ? _ctx.t('messages.recipients.notify') : _ctx.t('messages.recipients.recipients'),
          records: _ctx.searchableRecipients,
          "initial-selected-records": _ctx.selectedRecipients,
          onOnSelectedRecords: _ctx.setSelectedRecipients
        }, null, 8, ["title", "records", "initial-selected-records", "onOnSelectedRecords"])
      ]),
      _: 1
    }, 8, ["is-open"]),
    _createVNode(_component_IonButton, {
      onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.setOpen(true))),
      color: "primary",
      expand: "full",
      shape: "round"
    }, {
      default: _withCtx(() => [
        (_ctx.selectedRecipients.length > 0)
          ? (_openBlock(), _createElementBlock("span", _hoisted_1, _toDisplayString(_ctx.selectedRecipients.length) + " " + _toDisplayString(_ctx.t('recipient')) + _toDisplayString(_ctx.selectedRecipients.length > 1 ? 's' : ''), 1))
          : (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(_ctx.t(_ctx.variant === 'notify' ? 'notes.notifyUsers' : 'messages.selectRecipients')), 1))
      ]),
      _: 1
    })
  ]))
}