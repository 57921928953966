
import { IonContent, IonHeader, IonTitle, IonToolbar, IonSearchbar, IonIcon, IonList, IonItem, IonCheckbox, IonLabel } from '@ionic/vue';
import { defineComponent } from 'vue';
import {useI18n} from "vue-i18n";
import { checkmarkOutline } from 'ionicons/icons';

interface RecordInterface {
  id: number;
  label: string;
}

export default defineComponent({
  name: 'SearchModal',
  props: {
    title: {
      type: String,
      default: 'Action'
    },

    records: {
      type: Array,
      default: null,
    },

    initialSelectedRecords: {
      type: Array,
      default: () => [],
    }
  },

  watch: {
    query() {
      if (this.query.length > 0) {
        this.filteredRecords = this.records.filter((record: any) => {
          return ((record.label.toLowerCase()).search(this.query.toLowerCase()) >= 0) || this.selectedRecords.includes(record.id as never)
        }) as RecordInterface[];
      } else {
        this.filteredRecords = this.records as RecordInterface[];
      }
    },

    selectedRecords: {
      deep: true,
      handler() {
        this.$emit('onSelectedRecords', this.selectedRecords);
      }
    }
  },

  data() {
    return {
      query: '',
      filteredRecords: [] as RecordInterface[],
      selectedRecords: [] as number[],
    };
  },

  created() {
    this.filteredRecords = this.records as RecordInterface[];
    this.selectedRecords = this.initialSelectedRecords as number[];
  },

  setup() {
    const { t } = useI18n()
    return {
      t,
      checkmarkOutline
    }
  },
  components: { IonContent, IonHeader, IonTitle, IonToolbar, IonIcon, IonSearchbar, IonList, IonItem, IonCheckbox, IonLabel }
});
